<template>
    <div>
        <errors-bag-list></errors-bag-list>

        <b-card no-body class='mb-1 text-center' v-if='isDiff'>
            <b-card-header header-tag="header" class="p-2">
                <h3>{{ user.username }}</h3>
                <h4 v-html='currentWorkPosition'></h4>
            </b-card-header>

            <users-form v-model='user' :loginBarcodeExists='loginBarcodeExists'></users-form>

            <control-panel style='z-index:unset;'>
                <b-button variant="primary" @click='goToTimesheet()'>Табель рабочего времени</b-button>
            </control-panel>
        </b-card>

        <control-panel>
            <b-button variant="danger" @click="$router.go( -1 )">Отмена</b-button>
            <b-button variant="warning" @click="deleteUser" v-if='isDiff'>Удалить</b-button>
            <b-button variant="success" @click="saveUser" v-if='isDiff'>Сохранить</b-button>
        </control-panel>
    </div>
</template>

<script>
    import moment from 'moment';
    import cloneDeep from 'lodash/cloneDeep';

    import UsersForm from './UsersForm';
    import ControlPanel from '../_common/ControlPanel';
    import ErrorsBagList from '../_common/ErrorsBagList';

    import ErrorsBag from '../../utils/errorsBag';
    import { USERS_ENDPOINT } from '../../utils/endpoints';
    import { ROLE_STOCKMAN, ROLE_GROUP_SENIOR, ROLE_STORAGE_SENIOR } from '../../utils/Roles';

    const DEFAULT_USER = {
        id: null,
        roles: [],
        email: null,
        storages: [],
        username: null,
        fullname: null,
        password: null,
        is_active: true,
        login_barcode: null,
        followingWorkPositions: [],
        current_work_position: null,
        outstaff_coefficient_id: null,
        outstaff_coefficient_valid_from: null,
        outstaff_coefficient_disabled: false,
        rolesWithoutStorages: [],
        storagesGroupedByRole: {
            ROLE_STOCKMAN: [],
            ROLE_GROUP_SENIOR: [],
            ROLE_STORAGE_SENIOR: []
        },
        contractor_id: null,
    };

    export default {
        props: [ 'id' ],
        name: "UsersEdit",
        components: { UsersForm, ControlPanel, ErrorsBagList },
        computed: {
            isDiff() {
                return this.user && JSON.stringify( this.user ) !== JSON.stringify( DEFAULT_USER );
            },
            currentWorkPosition() {
                if ( !this.user.current_work_position ) {
                    return '';
                }

                let validFrom = moment( this.user.current_work_position.pivot.valid_from ).format( 'YYYY-MM-DD' );
                return ' (' + this.user.current_work_position.name.toLowerCase() + ' с ' + validFrom + ')';
            },
        },
        data() {
            return {
                ErrorsBag,
                user: DEFAULT_USER,
                loginBarcodeExists: false,
            };
        },
        methods: {
            goToTimesheet() {
                this.$router.push( { name: 'TimesheetDashboard' } );
            },

            deleteUser() {
                ErrorsBag.discard();

                if ( confirm( 'Вы уверены, что хотите удалить этого пользователя?' ) ) {
                    this.$http
                        .delete( USERS_ENDPOINT + '/' + this.id )
                        .then(
                            () => this.$router.push( { name: 'UsersList' } ),
                            response => ErrorsBag.fill( response.body )
                        );
                }
            },
            saveUser() {
                ErrorsBag.discard();

                let params = cloneDeep( this.user );
                params.is_active = this.user.is_active ? 1 : 0;

                this.$http
                    .put( USERS_ENDPOINT + '/' + this.id, params )
                    .then(() => {
                        this.$toast.success('Пользователь успешно сохранен');

                        this.fetchUser();
                    }).catch((error) => {
                        ErrorsBag.fill( error.body )
                        this.$toast.error( 'Есть ошибки! Ознакомьтесь со списком вверху страницы' );
                        window.scrollTo( { top: 0, behavior: 'smooth' } );
                    });
            },

            fetchUser() {
                this.$http
                    .get( USERS_ENDPOINT + '/' + this.id, {
                        params: {
                            with: [
                                'followingWorkPositions'
                            ],
                            appends: [
                                'rolesWithoutStorages',
                                'storagesGroupedByRole'
                            ]
                        }
                    } )
                    .then(
                        response => {
                            this.loginBarcodeExists = !!response.data.data.login_barcode;

                            let additionalParameters = { password: null, login_barcode: null };
                            this.user = Object.assign( {}, response.data.data, additionalParameters );
                        },
                        response => ErrorsBag.fill( response.body )
                    );
            },
        },
        created() {
            this.fetchUser();
        }
    }
</script>
